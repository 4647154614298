angular.module('public.teachers', [

    'ui.router',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('public.teachers', {
        url: 'teachers', // default public view
        templateUrl: 'public.teachers',
        resolve: {
            teachers: function(Teacher) {
                return Teacher.query();
            }
        },
        controller: 'PublicTeachersController'
    });

}).controller('PublicTeachersController', function ($scope, $rootScope, teachers) {

    $scope.teachers = teachers;

});
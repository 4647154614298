angular.module('public.resources', [
    'ngResource'
]).factory('User', function ($resource) {
    return $resource('/api/user');
}).factory('Text', function ($resource) {
    return $resource('/api/texts/:id');
}).factory('Announce', function ($resource) {
    return $resource('/api/announces/:id');
}).factory('Course', function ($resource) {
    return $resource('/api/courses/:id');
}).factory('Callback', function ($resource) {
    return $resource('/api/callbacks/:id');
}).factory('Teacher', function ($resource) {
    return $resource('/api/teachers/:id');
});
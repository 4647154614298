angular.module('public', [

    'ui.router',
    'public.home',
    'public.announces',
    'public.courses',
    'public.callbacks',
    'public.teachers',
    'public.gallery',
    'public.forum',
    'public.contacts',
    'private.cabinet',
    'public.login',
    //'public.templates.social',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('public', {
        abstract: true,
        url: '/',
        templateUrl: 'public',
        controller: 'PublicController'
    })

}).controller('PublicController', function ($scope, $state, Text) {

    console.log('public.controller()');
    var reload = function () {
        Text.get().$promise.then(function (data) {
            angular.extend($scope.text, data);
            $scope.$emit('$stateChangeSuccess');
        });
    };

    reload();
    $scope.$on('locale', reload);

});



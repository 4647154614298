angular.module('public.login', [

    'ui.router',
    'satellizer',
    'public.resources',
    'private',
    'templates'

]).config(function ($stateProvider) {

    $stateProvider.state('public.login', {
        url: 'login',
        onEnter: function ($uibModal) {
            $uibModal.open({
                animation: false,
                templateUrl: 'public.login',
                controller: 'LoginDialogController',
                size: 'sm'
            });
        }
    });

}).controller('LoginDialogController', function ($scope, $rootScope, $uibModalInstance, $state, Session) {

    $scope.credentials = {
        email: 'editor@example.com'
    };

    $scope.login = function () {
        Session.login($scope.credentials).then(
            function (success) {
                console.log('Session.login() resolved');
                $uibModalInstance.close();
                $state.go('private.cabinet');
            },
            function (error) {
                console.log('Session.login() rejected', error);
                $scope.error = error.data.error;
            }
        );
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss();
        console.log($rootScope.previousState.name);
        $state.go($rootScope.previousState);
    }
});
angular.module('public.courses', [

    'ui.router',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('public.courses', {
        url: 'courses', // default public view
        templateUrl: 'public.courses',
        resolve: {
            courses: function(Course) {
                return Course.query();
            }
        },
        controller: 'PublicCoursesController'
    });

}).controller('PublicCoursesController', function ($scope, $rootScope, courses) {

    $scope.courses = courses;

});
angular.module('public.forum', [
    'ui.router',
    'templates'
]).config(function ($stateProvider, $urlRouterProvider) {
    $stateProvider
        .state('public.forum', {
            url: 'forum',
            templateUrl: 'public.forum',
            controller: function ($scope) {
            }
        })
});
angular.module('public.callbacks', [
    'ui.router',
    'templates'
]).config(function ($stateProvider, $urlRouterProvider) {
        $stateProvider.state('public.callbacks', {
            url: 'callbacks',
            templateUrl: 'public.callbacks',
            controller: function ($scope, $rootScope, Callback) {

                var reload = function () {
                    Callback.query().$promise.then(function (result) {
                        $scope.callbacks = result;
                    });
                };

                reload();

                $scope.$on('locale', reload);
            }
        })
    }
);
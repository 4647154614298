angular.module('public.contacts', [
    'ui.router',
    'templates'
]).config(function ($stateProvider, $urlRouterProvider) {
    $stateProvider
        .state('public.contacts', {
            url: 'contacts',
            templateUrl: 'public.contacts',
            controller: function ($scope) {

            }
        })
});
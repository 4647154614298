angular.module('public.home', [

    'ui.router',
    'public.resources',
    'templates',
    'public.login'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('public.home', {
        url: '',
        templateUrl: 'public.home',
        controller: function ($scope) {
        }
    })

});
angular.module('public.announces', [
    'ui.router',
    'templates'
]).config(function ($stateProvider, $urlRouterProvider) {
        $stateProvider.state('public.announces', {
            url: 'announces',
            templateUrl: 'public.announces',
            controller: function ($scope, $rootScope, Announce) {

                var reload = function () {
                    Announce.query().$promise.then(function (result) {
                        $scope.announces = result;
                    });
                };

                reload();

                $scope.$on('locale', reload);
            }
        })
    }
);